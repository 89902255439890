<template>
  <base-material-card
    color="primary"
    icon="mdi-set-merge"
    inline
    class="px-5 py-4 ml-0 pb-10"
  >
    <template v-slot:after-heading>
      <div class="display-2 font-weight-light">
        Parâmetros Editáveis Pelos Usuários
      </div>
    </template>
    <v-divider class="my-4" />
    <v-row>
      <v-col
        cols="12"
        class="pt-0"
      >
        <tabela-visualizacao-parametros-editaveis
          v-if="JSON.stringify(parametrosEditaveis) !== '{}'"
          id="tabela-visualizacao-parametros-editaveis"
          :items="parametrosEditaveisArray"
          @delete:triggered="apagarParametro($event)"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-text-field
          v-model="nomeParametro"
          label="Nome do Parâmetro"
          outlined
        />
      </v-col>
    </v-row>
    <v-row class="mt-n9">
      <v-col cols="12">
        <v-card id="segundo-grupo-tabs-rm">
          <v-toolbar flat>
            <v-tabs
              v-model="tab"
              grow
            >
              <v-tab
                v-for="tab in itemsTabs"
                :key="tab"
              >
                {{ tab }}
              </v-tab>
            </v-tabs>
          </v-toolbar>
          <v-tabs-items v-model="tab">
            <v-tab-item
              v-for="tab in itemsTabs"
              :key="tab"
            >
              <template v-if="tab === 'Filtrar por Campo'">
                <v-row class="pt-1">
                  <v-col cols="6">
                    <v-select
                      class="ml-custom-rm"
                      v-model="obrigatorio"
                      :items="['Sim', 'Não']"
                      label="Obrigatório"
                      outlined
                    />
                  </v-col>
                  <v-col cols="6">
                    <v-autocomplete
                      class="ml-custom-rm v-autocompete-uppercase"
                      v-model="baseDados"
                      :items="basesDados"
                      item-value="nome_tabela"
                      item-text="descricao"
                      label="Bases de Dados"
                      outlined
                      :loading="loadingBasesDados"
                      @change="getBasesDadosCampos(baseDados)"
                    />
                  </v-col>
                </v-row>
                <template v-if="baseDados !== ''">
                  <v-row>
                    <v-col
                      cols="6"
                      class="py-0"
                    >
                      <v-autocomplete
                        class="ml-custom-rm v-autocompete-uppercase"
                        v-model="campo"
                        :items="campos"
                        item-text="column"
                        label="Campo"
                        return-object
                        outlined
                        :loading="loadingCampos"
                        @change="parametroDefault = false"
                      />
                    </v-col>
                    <v-col
                      cols="6"
                      class="py-0"
                    >
                      <v-select
                        v-if="JSON.stringify(campo) !== '{}'"
                        class="ml-custom-rm"
                        v-model="criterio"
                        :items="criteriosDisponiveis"
                        item-text="function"
                        return-object
                        label="Critério"
                        outlined
                      />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col
                      cols="6"
                      style="padding-left: 10px"
                    >
                      <v-checkbox
                        v-if="JSON.stringify(campo) !== '{}'"
                        class="ml-custom-rm"
                        v-model="parametroDefault"
                        label="Definir Parâmetro Default"
                      />
                    </v-col>
                    <v-col
                      :cols="getTipoParametros === 'LISTA' ? 12 : 6"
                      v-if="parametroDefault && campo.column"
                    >
                      <parametros-default-campos
                        ref="parDef"
                        class="ml-custom-rm"
                        :campo="campo.column"
                        :tipo="getTipoParametros"
                      />
                    </v-col>
                  </v-row>
                </template>
                <v-divider class="mt-4" />
                <v-row>
                  <v-col
                    cols="6"
                    class="ml-custom-rm"
                  >
                    <v-btn
                      color="primary"
                      style="float: left"
                      class="mr-0"
                      small
                      :disabled="disabledBtnAddFiltro"
                      @click="adicionarParametro()"
                    >
                      Adicionar Filtro
                    </v-btn>
                  </v-col>
                </v-row>
              </template>
              <template v-else>
                <v-row>
                  <v-col
                    cols="6"
                    class="pb-0"
                  >
                    <v-select
                      class="ml-custom-rm"
                      v-model="tipoDados"
                      :items="['TEXTO', 'INTEIRO', 'DECIMAL', 'DATA', 'LISTA']"
                      label="Tipo de Dados"
                      outlined
                      @change="parametroDefault2 = false"
                    />
                  </v-col>
                  <v-col
                    cols="6"
                    style="padding-left: 10px"
                  >
                    <v-checkbox
                      class="ml-custom-rm"
                      v-model="parametroDefault2"
                      label="Definir Parâmetro Default"
                    />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col
                    cols="6"
                    v-if="tipoDados !== 'LISTA'"
                  />
                  <v-col
                    :cols="tipoDados === 'LISTA' ? 12 : 6"
                    v-if="parametroDefault2 && tipoDados"
                  >
                    <parametros-default-campos
                      ref="parDef2"
                      class="ml-custom-rm"
                      :tipo="tipoDados"
                    />
                  </v-col>
                </v-row>
                <v-divider class="mt-4" />
                <v-row>
                  <v-col
                    cols="6"
                    class="ml-custom-rm"
                  >
                    <v-btn
                      color="primary"
                      style="float: left"
                      class="mr-0"
                      small
                      :disabled="disabledBtnAddValorUsuario"
                      @click="adicionarParametro('valorUsuario')"
                    >
                      Adicionar Valor do Usuário
                    </v-btn>
                  </v-col>
                </v-row>
              </template>
            </v-tab-item>
          </v-tabs-items>
        </v-card>
      </v-col>
    </v-row>
  </base-material-card>
</template>

<script>
import BasesDadosService from '@/services/BasesDadosService';

export default {
  components: {
    ParametrosDefaultCampos: () =>
      import(
        '@/components/power-ups/relatorios-modelos/ParametrosDefaultCampos'
      ),
    TabelaVisualizacaoParametrosEditaveis: () =>
      import(
        '@/components/power-ups/relatorios-modelos/TabelaVisualizacaoParametrosEditaveis'
      )
  },

  props: {
    relatorio: {
      type: Object,
      default: () => {}
    }
  },

  data: () => ({
    tab: null,
    itemsTabs: ['Filtrar por Campo', 'Valor do Usuário'],
    nomeParametro: '',
    obrigatorio: 'Não',
    basesDados: [],
    baseDados: '',
    campos: [],
    campo: {},
    criterio: {},
    parametroDefault: false,
    tipoDados: '',
    parametroDefault2: false,
    loadingBasesDados: false,
    loadingCampos: false,
    criteriosFactiveis: [
      {
        function: '=',
        translate: '=',
        types: [
          'boolean',
          'character',
          'smallint',
          'integer',
          'bigint',
          'numeric',
          'timestamp',
          'date',
          'character varying',
          'text'
        ]
      },
      {
        function: '≠',
        translate: '<>',
        types: [
          'boolean',
          'character',
          'smallint',
          'integer',
          'bigint',
          'numeric',
          'timestamp',
          'date'
        ]
      },
      {
        function: '≥',
        translate: '>=',
        types: [
          'boolean',
          'character',
          'smallint',
          'integer',
          'bigint',
          'numeric',
          'timestamp',
          'date'
        ]
      },
      {
        function: '≤',
        translate: '<=',
        types: [
          'boolean',
          'character',
          'smallint',
          'integer',
          'bigint',
          'numeric',
          'timestamp',
          'date'
        ]
      },
      {
        function: '>',
        translate: '>',
        types: [
          'boolean',
          'character',
          'smallint',
          'integer',
          'bigint',
          'numeric',
          'timestamp',
          'date'
        ]
      },
      {
        function: '<',
        translate: '<',
        types: [
          'boolean',
          'character',
          'smallint',
          'integer',
          'bigint',
          'numeric',
          'timestamp',
          'date'
        ]
      },
      {
        function: 'Lista',
        translate: 'IN',
        types: [
          'smallint',
          'character',
          'integer',
          'bigint',
          'character varying',
          'text'
        ]
      },
      {
        function: 'Fora da Lista',
        translate: 'NOT IN',
        types: [
          'smallint',
          'character',
          'integer',
          'bigint',
          'numeric',
          'character varying',
          'text'
        ]
      },
      {
        function: 'Contém',
        translate: 'ILIKE %X%',
        types: ['character varying', 'text']
      },
      {
        function: 'Não contém',
        translate: 'NOT ILIKE %X%',
        types: ['character varying', 'text']
      },
      {
        function: 'Começa com',
        translate: 'ILIKE X%',
        types: ['character varying', 'text']
      },
      {
        function: 'Termina com',
        translate: 'ILIKE %X',
        types: ['character varying', 'text']
      }
    ],
    parametrosEditaveis: {}
  }),

  computed: {
    criteriosDisponiveis() {
      return this.criteriosFactiveis.filter((el) =>
        el.types.includes(this.campo.type)
      );
    },

    disabledBtnAddFiltro() {
      return !(
        this.nomeParametro &&
        this.baseDados &&
        JSON.stringify(this.campo) !== '{}' &&
        JSON.stringify(this.criterio) !== '{}'
      );
    },

    disabledBtnAddValorUsuario() {
      return !(this.nomeParametro && this.tipoDados);
    },

    getTipoParametros() {
      return !['Lista', 'Fora da Lista'].includes(this.criterio.function)
        ? this.traduzirTipo(this.campo.type)
        : 'LISTA';
    },

    /**
     * Retorna o valor da variavel parametrosEditaveis em forma de array
     */
    parametrosEditaveisArray() {
      let array = [];
      let objParametros = this.parametrosEditaveis;

      Object.keys(objParametros).forEach((key) => {
        objParametros[key].index = key.replace(
          'parametroEditavel',
          ''
        ); /* Adiciona coluna index ao objeto */
        array.push(objParametros[key]);
      });

      return array;
    }
  },

  created() {
    this.getBasesDados();
    if (this.$route.params.id) this.carregarValoresCampos();
  },

  methods: {
    getBasesDados() {
      this.loadingBasesDados = true;
      BasesDadosService.getBasesDadosLiberadas()
        .then((response) => (this.basesDados = response.data))
        .catch(() => {
          this.$toast.error('Erro ao recuperar bases de dados.', '', {
            position: 'topRight'
          });
        })
        .finally((this.loadingBasesDados = false));
    },

    getBasesDadosCampos(baseDados) {
      this.loadingCampos = true;
      BasesDadosService.getBasesDadosCampos(baseDados)
        .then((campos) => (this.campos = campos))
        .catch(() => {
          this.$toast.error('Erro ao recuperar bases de dados.', '', {
            position: 'topRight'
          });
        })
        .finally((this.loadingCampos = false));
    },

    traduzirTipo(tipo) {
      switch (tipo) {
        case 'smallint':
        case 'integer':
        case 'bigint':
          return 'INTEIRO';
        case 'numeric':
          return 'DECIMAL';
        case 'date':
          return 'DATA';
        case 'character varying':
        case 'text':
        case 'timestamp':
        case 'LISTA':
          return 'TEXTO';
        default:
          return tipo.toUpperCase();
      }
    },

    adicionarParametro(tipo = 'filtro') {
      let numParam = Number(Object.keys(this.parametrosEditaveis).length + 1);
      let classeParam = '';
      let listaParam = '';
      let obrigatorioParam = 0;
      this.obrigatorio === 'Não' ? 0 : 1;
      let defaultParam = '';
      let criterioParam = '';
      let campoParam = '';

      if (tipo === 'filtro') {
        classeParam = this.traduzirTipo(this.campo.type).toLowerCase();
        criterioParam = this.criterio.translate;
        listaParam =
          criterioParam === 'IN' || criterioParam === 'NOT IN' ? true : false;
        obrigatorioParam = this.obrigatorio === 'Não' ? 0 : 1;
        campoParam = `${this.baseDados}.${this.campo.column}`;
        if (this.parametroDefault)
          defaultParam = this.$refs.parDef[0].exportValorDefault();
      } else {
        classeParam =
          this.tipoDados !== 'LISTA'
            ? this.traduzirTipo(this.tipoDados).toLowerCase()
            : this.tipoDados.toLowerCase();
        criterioParam = this.tipoDados === 'LISTA' ? 'IN' : '';
        listaParam = this.tipoDados === 'LISTA' ? true : false;
        obrigatorioParam = 1;
        if (this.parametroDefault2)
          defaultParam = this.$refs.parDef2[0].exportValorDefault();
      }

      this.$set(this.parametrosEditaveis, `parametroEditavel${numParam}`, {
        nome: this.nomeParametro,
        classe: classeParam,
        tipoParametro: tipo,
        lista: listaParam,
        obrigatorio: obrigatorioParam,
        default: defaultParam,
        criterio: criterioParam,
        campo: campoParam
      });

      this.$emit(
        'change-parametros:adicionado',
        `parametroEditavel${numParam}`
      );

      this.limparCampos();
    },

    apagarParametro(index) {
      this.$delete(this.parametrosEditaveis, `parametroEditavel${index}`);
      this.$emit('change-parametros:removido', `parametroEditavel${index}`);
    },

    limparCampos() {
      this.nomeParametro = this.baseDados = this.tipoDados = '';
      this.obrigatorio = 'Não';
      this.campo = this.criterio = {};
      this.parametroDefault = this.parametroDefault2 = false;
      this.$emit('reset:error-messages');
    },

    carregarValoresCampos() {
      this.parametrosEditaveis = this.relatorio.parametros_editaveis;
    },

    exportCampos() {
      return { parametros_editaveis: JSON.stringify(this.parametrosEditaveis) };
    }
  },

  watch: {
    tab() {
      this.limparCampos();
    }
  }
};
</script>

<style>
.ml-custom-rm {
  margin-left: 42px !important;
  margin-right: 42px !important;
}
.v-autocompete-uppercase input {
  text-transform: uppercase !important;
}
</style>
